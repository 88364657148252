/**
 * 白色主题配置文件
 * @type {{}}
 */
const colorConf = {
    // header部分
    headerColor: '#F9FBFD',
    headerbottomLineColor: '#e5e5e5e',
    headerIconColor: '#383838',
    searchBoxColor: '#A6A6A6',
    searchBoxFontColor: '#000000',
    buttonColor: '#ffffff',
    buttonBackColor: '#2F64B5',
    dropdownColor: '#383838',
    // 左菜单部分
    leftMenuColor: '#ffffff',
    leftMenuIconColor: '#383838',
    leftMenuFontColor: '#383838',
    leftMenuHoverColor: '#eeeeee',
    leftMenuCheckedColor: '#E6F1FE',
    leftMenuCheckedFontColor: '#2F64B5',
    leftMenuCheckedIconColor: '#2F64B5',
    leftMenuPopFontColor: '#ffffff',
};
export default colorConf;