
import request from "@/utils/request"
import serviceConfig from '@/network/serviceConfig'
// 创建模板
export function myCreations(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/myCreations`,
        method: "post",
        data: params,
    })
}
// 获取我创建的模板
export function getMyCreations(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/myCreations`,
        method: "get",
        params: params,
    })
}
// 模板评论
export function templateComments(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/${params.fileTemplateId}/comments`,
        method: "post",
        data: params.data
    })
}
// 获取模板评论
export function getTemplateComments(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/${params.fileTemplateId}/comments`,
        method: "get",
    })
}
// 获取我收藏
export function getMyCollections(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/myCollections`,
        method: "get",
        params: params,
    })
}
// 获取自定义模板
export function getTenantCreation(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/tenantCreation`,
        method: "get",
        params: params,
    })
}
// 获取预设模板
export function getPreInstall(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/preInstall`,
        method: "get",
        params: params,
    })
}
// 模糊搜索
export function getFuzzyQuery(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/fuzzyQuery`,
        method: "get",
        params: params,
    })
}
// 删除模板
export function deleteTemplate(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/${params.fileTemplateId}`,
        method: "delete"
    })
}
// 收藏模板
export function collectFileTemplate(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/myCollections`,
        method: "post",
        data: params
    })
}
// 取消收藏模板
export function cancelCollectFileTemplate(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/myCollections/${params.fileTemplateId}`,
        method: "delete",
    })
}
// 通过模板创建文件
export function applyTemplate(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/${params.fileTemplateId}/apply`,
        method: "post",
        data: params.data
    })
}
// 创建模板标签
export function creatFileTemplateTags(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplateTags`,
        method: "post",
        data: params.data
    })
}
// 修改模板标签
export function editFileTemplateTags(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplateTags/${params.tagId}`,
        method: "put",
        data: params.data
    })
}
// 获取模板标签
export function getFileTemplateTagListQuery(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/queryByTag`,
        method: "get",
        params: params,
    })
}
export function getFileTemplateTagList(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplateTags`,
        method: "get",
    })
}
export function deleteFileTemplateTagList(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplateTags/${params.tagId}`,
        method: "delete",
    })
}
// 修改单个模板信息
export function editFileTemplate(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/fileTemplates/${params.fileTemplateId}`,
        method: "put",
        data: params.data
    })
}