import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 异步任务状态枚举
export function asyncTaskStatusList() {
    return request({
        url: `/${serviceConfig['file-manage-service']}/asyncTaskStatusList`,
        method: 'get'
    })
}
// 异步任务类型枚举
export function asyncTaskTypeList() {
    return request({
        url: `/${serviceConfig['file-manage-service']}/asyncTaskTypeList`,
        method: 'get'
    })
}
// 异步任务表格数据
export function asyncTaskList(params) {
    return request({
       url: `/${serviceConfig['file-manage-service']}/asyncTaskList`,
        method: 'get',
        params: params
    })
}