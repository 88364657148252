import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
export function get_ed() {
    return request({
        url: `/${serviceConfig['file-manage-service']}/newUserGuide/events`,
        method: 'get'
    })
}
export function get_b() {
    return request({
        url: `/${serviceConfig['file-manage-service']}/newUserGuide/reward`,
        method: 'get'
    })
}

export function ed(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/newUserGuide/events`,
        data: params,
        method: 'post'
    })
}