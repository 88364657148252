import ThemeCommon from './common';
import darkConf from './dark';
import lightConf from './light';

class ChangeTheme {
  constructor() {
    this.common = ThemeCommon;
    this.configMap = {
      'dark': darkConf,
      'light': lightConf,
    }
  };
  // 将所有组件的css合并
  mergeCss(theme) {
    if (theme && this.configMap[theme]) {
      const themeConf = this.configMap[theme]
      const HeaderCss = this.common.getHeaderCss(themeConf);
      const LeftMenuCss = this.common.getLeftMenuCss(themeConf);
      let allCss = `${HeaderCss}${LeftMenuCss}`;
      const style = this.appendStyle();
      style.innerHTML = allCss || '';
    } else {
      const style = this.appendStyle();
      style.innerHTML = '';
    }
  };

  appendStyle() {
    const themeId = 'ytkj-theme';
    let styleEl = document.getElementById(themeId);
    if (styleEl) return styleEl;
    styleEl = document.createElement('style');
    styleEl.id = themeId;
    document.body.appendChild(styleEl);
    return styleEl;
  };
};
export default new ChangeTheme();
