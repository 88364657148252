import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
export function project_Key_Verification(projectKey) {
    return request({
        url: `/${serviceConfig['project-service']}/projectKeyVerification`,
        method: 'get',
        params: {
            projectKey
        },
    })

}

export function project_Name_Verification(projectName) {
    return request({
        url: `/${serviceConfig['project-service']}/projectNameVerification`,
        method: 'get',
        params: {
            projectName
        },
    })

}