/**
 * 黑色主题配置文件
 * @type {{}}
 */
const colorConf = {
    // header部分
    headerColor: '#262F3E',
    headerbottomLineColor: '#262F3E',
    headerIconColor: '#BFC4C6',
    searchBoxColor: '#334257',
    searchBoxFontColor: '#BFC4C6',
    buttonColor: '#ffffff',
    buttonBackColor: '#2F64B5',
    dropdownColor: '#ffffff',
    // 左菜单部分
    leftMenuColor: '#1E222D',
    leftMenuIconColor: '#BFC4C6',
    leftMenuFontColor: '#ffffff',
    leftMenuHoverColor: '#0753B0',
    leftMenuCheckedColor: '#0753B0',
    leftMenuCheckedFontColor: '#ffffff',
    leftMenuCheckedIconColor: '#ffffff',
    leftMenuPopFontColor: '#ffffff',
};
export default colorConf;